body {
    font-size: 0.875rem;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
* Sidebar
*/

.sidebar {
    position: fixed !important;
    top: 5rem;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 0;
    background-color: #e9ecef !important;

    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 48px; /* Height of navbar */
        height: calc(100vh - 48px);
        padding-top: 0.5rem;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */

        .nav-link {
            font-weight: 500;
            color: #333;

            .feather {
                margin-right: 4px;
                color: #999;
            }

            &.active,
            &:hover {
                color: #007bff;
            }

            &:hover .feather,
            &.active .feather {
                color: inherit;
            }
        }
    }
}

.sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
}

/*
* Navbar
*/

.navbar {
    background-color: #0833ff;

    .nav-link {
        color: white !important;
    }
    .navbar-brand {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-size: 1rem;
        background-color: #0833ff;
    }

    .form-control {
        padding: 0.75rem 1rem;
        border-width: 0;
        border-radius: 0;
    }

    .form-control-dark {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.1);

        &:focus {
            border-color: transparent;
            box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
        }
    }
}
/*
* Utilities
*/

.border-top {
    border-top: 1px solid #e5e5e5;
}
.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

table tr:hover {
    cursor: pointer;
}

.table-hover {
    tbody {
        tr {
            &:hover {
                background-color: rgba(172, 170, 170, 0.08) !important;
            }
        }
    }
}

.blocks .btn-primary {
    margin: 0 5px;
    border-radius: 0;
}

.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: auto;
    text-align: center;

    .checkbox {
        font-weight: 400;
    }
    .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;

        &:focus {
            z-index: 2;
        }
    }

    input {
        &[type="email"] {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        &[type="password"] {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
