//  navbar

.navbar-brand {
    img {
        width: 70%;
    }
}

// modal

.modal-container {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-overlay {
        opacity: 0.3;
        background-color: #33373a;
        z-index: 500;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
    }

    .modal-content {
        background-color: #ffffff;
        box-shadow: 0px 3px 6px #00000029;
        z-index: 1500;
        height: auto;
        max-height: 100vh;
        width: fit-content;
        overflow-x: auto;
    }
}

.auto-complete {
    width: 100%;
    position: relative;

    .options {
        height: auto;
        max-height: 20vh;
        overflow-y: auto;
        position: fixed;
        z-index: 10;

        ul {
            width: 36vw;
            max-width: 538px;
            border: 1px solid #d1d1d1;
            background-color: white;
            margin-bottom: 0;

            li {
                list-style: none;
                padding: 0 3px;
                padding-left: 0.75rem;
                cursor: pointer;

                &:hover {
                    background-color: #e0e3f1;
                }
            }
        }
    }
}

// table
table {
    tr {
        &.gray {
            background-color: #c7c7c7 !important;

            &:hover {
                background-color: #c7c7c7 !important;
            }
        }
    }
}

// paginate section

.custom- {
    border-top: 1px solid #d9dbdf;
    width: 100%;
    padding-top: 1.5rem;

    margin: auto;
    padding: 0.75rem;
    align-items: center;
    .disabled {
        pointer-events: none;
        a {
            color: #d5d5e4;
        }
    }

    li {
        margin: 0 0.5rem;
    }

    .page-link {
        border: none;
        z-index: unset;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 22px;
        height: 22px;
        border-radius: 2px;
        &:focus {
            box-shadow: none;
        }
    }

    .active {
        .page-link {
            background-color: #616ea9;
        }
    }

    a {
        color: #bcbccb;
        //background: #fff;
        cursor: pointer;
    }

    .active {
        a {
            color: #000;
            background: transparent;
        }
    }
}

// custom-pagination

.custom-pagination {
    display: flex;
    align-items: center;
    text-decoration: none;
    list-style: none;
    padding-left: 0;
    background-color: white;
    border-top: 1px solid #dee2e6;
    .disabled {
        display: none;
    }

    .page-item {
        margin-left: 0.55rem;
        margin-right: 0.55rem;
        box-shadow: 0 6px 20px rgba(32, 32, 64, 0.12);
        min-width: 30px;
        min-height: 30px;
        border-radius: 0.3rem;

        cursor: pointer;

        .page-link,
        button {
            border: none !important;
            border-radius: 0.3rem;
            min-width: 30px;
            min-height: 30px;
            box-shadow: none;
            margin-left: 0;
            font-weight: normal;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
        }
    }
}
