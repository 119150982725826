/*
Global Search Bar
*/

.filter-search {
    .search-img {
        position: absolute;
        left: 0.6rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.9rem;
    }

    .form-control {
        padding-left: 2.25rem;
    }
}


/*
Claim Page
*/
.claim-page {
    .claim-header-section {
        .action-buttons {
            button {
                width: 150px;
            }
        }
    }
}

/*
Line Item Modal
*/
.line-item-modal {
    width: 40vw;
    padding: 5%;
    max-width: 600px;

    .details {
        font-weight: bold;

        .label {
            width: 22%;
        }

        .desc {
            width: 78%;
        }
    }

    ul {
        padding-left: 0;

        li {
            list-style: none;
        }
    }
}

/*
Confirmation Modal
*/
.confirmation-modal {
    width: 35vw;
    padding: 7%;
    max-width: 600px;
}
